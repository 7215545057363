.site-layout-background {
    background: #fff;
  }
  
  .logo {
    height: 32px;
    margin: 16px;
     
  }
  
  .menu-button {
    display: none;
  }
  
  @media (max-width: 992px) {
    .menu-button {
      display: inline-block;
    }
  }
  