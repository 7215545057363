.btnx {
  width: 39%;
  padding: 5px;
  border: none;
  margin: 3px;
  border-radius: 10px;
}

.badd {
  background-color: #2d438e !important;
  color: white;
}
.bedit {
  background-color: #df2528 !important;
  color: white;
}
