@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
body {
  font-weight: 400;
  /* background-color: black; */
  /* height: 100vh; */
  /* font-family: "Montserrat", sans-serif; */
  background: repeating-linear-gradient(
    90deg,
    #0000 0 calc((100% - 1 * 1px) / 6),
    rgb(211, 211, 227) 0 calc((100% - 1 * 1px) / 6 + 0.8px)
  );
  background-color: #e2e7ee;
  background-image: url("./img/wallpapertd.png");
  height: 100vh;
  /* font-family: "Quicksand", sans-serif; */
}
a {
  text-decoration: none;
  color: inherit;
}
.menu ul {
  width: 50%;
  border-bottom: 1px solid pink;
  margin: 0;
  padding: 0;
  display: flex;
  margin: auto;
  padding: 10px;
  list-style: none;
}
.menu ul li {
  flex: 1;
  margin: 0;
  padding: 0;
  text-align: center;
  border: 1px solid lightgrey;
  padding: 10px;
}
.header {
  position: fixed;
  background-color: white;
  /* width: 100%; */
  left: 0;
  right: 0;
  top: 0;
}
