.addSide input {
  margin-right: 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  width: 25%;
  /* background-color: green; */
}
.addSide button {
  background-color: grey;
  width: "";
}

.ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  
}
.ul li {
  flex: 1;
}
