.dataFloor ul {
  display: flex;
  margin: 0;
  padding: 0;
  /* display: flex; */
}
.dataFloor ul li {
  /* display: flex; */

  margin: 0;
  padding: 0;
  /* flex: 1; */
  margin-top: 8px;
  border-bottom: 1px solid grey;
  list-style: none;
  width: 15%;
}
.addSide input{
 width: 80%;
}